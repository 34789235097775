import React, { useState } from 'react';
import * as PropTypes from 'prop-types';
import cx from 'classnames';
import useMedia from 'use-media';
import Slider from '../Slider';
import BannerSliderItem from '../BannerSliderItem';

const BannerSlider = React.memo(({ slides, handleBtnClick }) => {
    const isMobile = useMedia({ maxWidth: 640 });
    const [isLoaded, setIsLoaded] = useState(false);

    if (!slides || !slides.length) return '';

    return (
        <div>
            <Slider
                id='bannerSlider'
                arrows={!isMobile && isLoaded}
                autoplay={true}
                options={{
                    created: () => {
                        setIsLoaded(true);
                    },
                    mounted: () => {
                        setIsLoaded(false);
                    },
                    destroyed: () => {
                        setIsLoaded(false);
                    },
                }}
                slideClassName={cx({
                    'border-0 opacity-1': isLoaded,
                    'border opacity-0': !isLoaded,
                })}
                arrowsWrapperClassName={cx({
                    'opacity-0 hidden': !isLoaded,
                    'opacity-1 block': isLoaded,
                })}
            >
                {slides.map((item, index) => (
                    <BannerSliderItem
                        key={`${item.image}+${index}`}
                        index={index}
                        item={item}
                        isMobile={isMobile}
                        handleBtnClick={handleBtnClick}
                    />
                ))}
            </Slider>
        </div>
    );
});

BannerSlider.defaultProps = {};

BannerSlider.propTypes = {
    slides: PropTypes.array.isRequired,
    handleBtnClick: PropTypes.func.isRequired,
};

export default BannerSlider;
