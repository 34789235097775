import React from 'react';
import * as PropTypes from 'prop-types';
import cx from 'classnames';
import { ChevronLeftIcon, ChevronRightIcon } from 'icons/solid';

const CircleIcon = ({ right, className, iconSize, circleSize, circleBg, isDisabled, onClick }) => {
    const Icon = right ? ChevronRightIcon : ChevronLeftIcon;
    const buttonClassName = cx(
        className,
        circleBg,
        `${circleSize ? circleSize : 'w-12 h-12'}`,
        'rounded-full z-75',
        'transition transform duration-300 ease-out',
        {
            'bg-brand-primary-100 shadow-down hover:bg-brand-primary-300 hover:shadow-heavy':
                !isDisabled && !circleBg,
            'bg-ui-gray-300 cursor-default': isDisabled,
        }
    );
    return (
        <button className={buttonClassName} onClick={onClick}>
            <Icon
                className={cx('block', iconSize ? iconSize : 'mx-auto h-6 lg:h-8', {
                    'text-ui-gray-500': isDisabled,
                })}
            />
        </button>
    );
};

CircleIcon.defaultProps = {
    right: false,
    isDisabled: false,
};

CircleIcon.propTypes = {
    right: PropTypes.bool,
    isDisabled: PropTypes.bool,
    iconSize: PropTypes.string,
    circleSize: PropTypes.string,
};

export default CircleIcon;
