import React from 'react';
import * as PropTypes from 'prop-types';
import cx from 'classnames';
import Image from '../../atoms/Image';
import Badge from '../../atoms/Badge';
import Link from '../../atoms/Link';
import BannerSliderItemCard from '../BannerSliderItemCard';
import styles from './BannerSliderItem.module.scss';

const BackgroundImage = ({ title, image, index }) => {
    return (
        <div className='absolute inset-0 h-full w-full'>
            <div className='relative h-full min-h-full w-full'>
                <Image
                    alt={title}
                    src={image}
                    layout='fill'
                    objectFit='cover'
                    objectPosition='center'
                    quality={70}
                    priority={index === 0}
                />
            </div>
        </div>
    );
};

const SlideWrapper = ({ children, href }) => {
    if (!href) return children;
    return (
        <Link className='block' underline={false} href={href}>
            {children}
        </Link>
    );
};

const BannerSliderItem = ({ index, item, isMobile, handleBtnClick }) => {
    const { title, alignment, image, button, link, background_color } = item;

    const buttonLink = button.link;
    const noBackground = background_color === 'no';
    const center = alignment === 'center';
    const left = alignment === 'left';
    const right = alignment === 'right';

    const sliderItemWrapperClassName = cx('w-full flex relative mx-auto', {
        'xxs:items-end': !center && noBackground,
        'xxs:items-center': center && noBackground,
        'sm:justify-center sm:items-center': center,
        'sm:justify-start sm:items-end': left && !noBackground,
        'sm:justify-start sm:items-center': left && noBackground,
        'sm:justify-end sm:items-end': right,
        'sm:justify-end sm:items-center': right && noBackground,
        'px-3 justify-center items-end': !noBackground,
    });

    const sliderItemCardWrapperClassName = 'w-full sm:w-3/4 lg:w-1/2';

    const sliderItemCardClassName = cx({
        'sm:p-12 sm:rounded-xl sm:text-center': center,
        'sm:p-12 sm:rounded-tl-xl sm:rounded-tr-xl sm:rounded-b-none sm:text-left': left,
        'sm:p-12 sm:rounded-tl-xl sm:rounded-tr-xl sm:text-right': right,
        'px-4 pb-6': noBackground,
        'px-6 pt-8 pb-8 w-full rounded-tl-xl rounded-tr-xl rounded-b-none bg-opacity-90 text-center mx-auto':
            !noBackground,
        'text-center': center && noBackground,
        'text-left': left && noBackground,
        'text-right': right && noBackground,
    });

    const badgeClassName = cx('absolute right-0', {
        'top-0 text-caption': isMobile,
        'bottom-0': !isMobile,
    });

    return (
        <SlideWrapper
            href={buttonLink !== '' && button.text === '' ? buttonLink : null} // Slide is clickable if button has no text
        >
            <div className={cx('relative w-full px-4 pt-5 md:px-8', styles.SlideWrapper)}>
                <BackgroundImage title={title} image={image} index={index} />

                <div
                    className={sliderItemWrapperClassName}
                    style={{
                        maxWidth: '1052px',
                        height: !isMobile ? '500px' : '350px',
                    }}
                >
                    {buttonLink !== '' && button.text !== '' ? (
                        <Link
                            href={buttonLink}
                            underline={false}
                            className={sliderItemCardWrapperClassName}
                        >
                            <BannerSliderItemCard
                                info={item}
                                className={sliderItemCardClassName}
                                handleBtnClick={handleBtnClick}
                                isMobile={isMobile}
                            />
                        </Link>
                    ) : (
                        <div className={sliderItemCardWrapperClassName}>
                            <BannerSliderItemCard
                                info={item}
                                className={sliderItemCardClassName}
                                handleBtnClick={handleBtnClick}
                                isMobile={isMobile}
                            />
                        </div>
                    )}
                </div>

                {link.text && (
                    <Badge
                        className={badgeClassName}
                        href={link.link}
                        type={isMobile ? 'linkTop' : 'linkBottom'}
                    >
                        {link.text}
                    </Badge>
                )}
            </div>
        </SlideWrapper>
    );
};

BannerSliderItem.defaultProps = {};

BannerSliderItem.propTypes = {
    index: PropTypes.number,
    item: PropTypes.object,
    isMobile: PropTypes.bool,
    handleBtnClick: PropTypes.func.isRequired,
};

export default BannerSliderItem;
