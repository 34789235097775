import React from 'react';
import * as PropTypes from 'prop-types';
import cx from 'classnames';
import CircleIcon from '../../atoms/CircleIcon';

const SliderControlArrows = ({
    arrowsWrapperClassName,
    arrowsClassName,
    arrowCircleSize,
    arrowCircleBg,
    arrowIconSize,
    leftArrowPosition,
    rightArrowPosition,
    slider,
    currentSlide,
}) => {
    const circleIconClassName = cx('arrow', arrowsClassName);
    const isLoop = slider.options().loop;
    const disabledRight = currentSlide === slider.details().size - slider.details().slidesPerView;
    const disabledLeft = currentSlide === 0;

    return (
        <div className={arrowsWrapperClassName}>
            <CircleIcon
                className={cx(
                    circleIconClassName,
                    'arrow--left',
                    `${leftArrowPosition ? leftArrowPosition : 'left-4'}`,
                    {
                        'hover:-translate-x-1': !disabledLeft,
                    }
                )}
                circleSize={arrowCircleSize ? arrowCircleSize : 'w-10 h-10 lg:w-12 lg:h-12'}
                iconSize={arrowIconSize}
                onClick={(e) => e.stopPropagation() || slider.prev()}
                isDisabled={!isLoop && disabledLeft}
                circleBg={arrowCircleBg}
            />

            <CircleIcon
                right={true}
                className={cx(
                    circleIconClassName,
                    'arrow--right',
                    `${rightArrowPosition ? rightArrowPosition : 'right-4'}`,
                    {
                        'hover:translate-x-1': !disabledRight,
                    }
                )}
                circleSize={arrowCircleSize ? arrowCircleSize : 'w-10 h-10 lg:w-12 lg:h-12'}
                iconSize={arrowIconSize}
                onClick={(e) => e.stopPropagation() || slider.next()}
                isDisabled={!isLoop && disabledRight}
                circleBg={arrowCircleBg}
            />
        </div>
    );
};
SliderControlArrows.defaultProps = {};

SliderControlArrows.propTypes = {
    arrowsWrapperClassName: PropTypes.string,
    arrowsClassName: PropTypes.string,
    arrowCircleSize: PropTypes.string,
    arrowCircleBg: PropTypes.string,
    arrowIconSize: PropTypes.string,
    leftArrowPosition: PropTypes.string,
    rightArrowPosition: PropTypes.string,
    slider: PropTypes.object,
    currentSlide: PropTypes.number,
};

export default SliderControlArrows;
