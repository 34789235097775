import React from 'react';
import * as PropTypes from 'prop-types';
import cx from 'classnames';
import BulletIndicator from '../../atoms/BulletIndicator';

export const SliderControlBullets = ({
    bulletsWrapperClassName,
    bulletType,
    bulletsClassName,
    bulletSize,
    currentSlide,
    slider,
}) => {
    const wrapperClasName = cx(bulletsWrapperClassName, 'dots inline-flex');

    return (
        <div className={wrapperClasName}>
            {[...Array(slider.details().size).keys()].map((idx) => (
                <BulletIndicator
                    key={idx}
                    type={bulletType}
                    onClick={() => {
                        slider.moveToSlideRelative(idx);
                    }}
                    className={cx(
                        bulletsClassName,
                        'cursor-pointer bg-ui-gray-300 hover:opacity-50',
                        { '!bg-ui-gray-900': currentSlide === idx }
                    )}
                    bulletSize={bulletSize ? bulletSize : 'w-2.5 h-2.5 lg:w-3 lg:h-3'}
                />
            ))}
        </div>
    );
};

SliderControlBullets.defaultProps = {
    bulletType: 'dot',
};

SliderControlBullets.propTypes = {
    bulletsWrapperClassName: PropTypes.string,
    bulletType: PropTypes.string,
    bulletsClassName: PropTypes.string,
    bulletSize: PropTypes.string,
    currentSlide: PropTypes.number,
    slider: PropTypes.object,
};

export default SliderControlBullets;
