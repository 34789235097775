import React, { useState, useEffect, useRef } from 'react';
import * as PropTypes from 'prop-types';
import cx from 'classnames';
import { useKeenSlider } from 'keen-slider/react';
import SliderControlArrows from '../SliderControlArrows';
import SliderControlBullets from '../SliderControlBullets';
import styles from './Slider.module.scss';

const SliderItemWrapper = ({ children, className }) => {
    return (
        <>
            {React.Children.map(children, (child) => {
                return <div className={cx(className, 'keen-slider__slide')}>{child}</div>;
            })}
        </>
    );
};

const Slider = ({
    children,
    sliderWrapperClassName,
    slideClassName,

    arrows,
    arrowsWrapperClassName,
    arrowsClassName,
    arrowCircleSize,
    arrowCircleBg,
    arrowIconSize,
    leftArrowPosition,
    rightArrowPosition,
    bullets,
    bulletsWrapperClassName,
    bulletType,
    bulletsClassName,
    bulletSize,

    id,
    options,
    autoplay,
    autoplaySpeed,
    refresh,
}) => {
    const [currentSlide, setCurrentSlide] = useState(0);
    const [pause, setPause] = useState(false);
    const containerRef = useRef(null);
    const timer = useRef(null);

    const initialOptions = {
        initial: 0,
        loop: true,
        duration: 1000,
        dragStart: () => {
            setPause(true);
        },
        dragEnd: () => {
            setPause(false);
        },
        slideChanged(s) {
            setCurrentSlide(s.details().relativeSlide);
        },
        controls: true,
        spacing: 0,
        breakpoints: {
            '(min-width: 640px)': {
                controls: false,
                slidesPerView: 1,
            },
        },
        resetSlide: true,
    };
    const [sliderRef, slider] = useKeenSlider({
        ...initialOptions,
        ...options,
    });

    useEffect(() => {
        if (slider && refresh) {
            slider.refresh({ ...initialOptions, ...options });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [id, refresh]);

    useEffect(() => {
        if (autoplay) {
            containerRef.current.addEventListener('mouseover', () => {
                setPause(true);
            });
            containerRef.current.addEventListener('mouseout', () => {
                setPause(false);
            });
        }
    }, [containerRef, autoplay]);

    useEffect(() => {
        if (autoplay) {
            timer.current = setInterval(
                () => {
                    if (!pause && slider) {
                        slider.next();
                    }
                },
                autoplaySpeed ? autoplaySpeed : 6000
            );
        }
        return () => {
            clearInterval(timer.current);
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [pause, slider]);

    return (
        <div
            ref={containerRef}
            className={cx(styles.KeenSliderWrapper, sliderWrapperClassName, 'relative')}
        >
            <div ref={sliderRef} id={id} className='keen-slider'>
                <SliderItemWrapper children={children} className={cx(slideClassName)} />
            </div>

            {arrows && slider && (
                <SliderControlArrows
                    slider={slider}
                    currentSlide={currentSlide}
                    arrowsWrapperClassName={arrowsWrapperClassName}
                    arrowsClassName={cx(arrowsClassName, 'absolute -mt-6 top-1/2')}
                    arrowCircleSize={arrowCircleSize}
                    arrowCircleBg={arrowCircleBg}
                    arrowIconSize={arrowIconSize}
                    leftArrowPosition={leftArrowPosition}
                    rightArrowPosition={rightArrowPosition}
                />
            )}

            {bullets && slider && (
                <SliderControlBullets
                    slider={slider}
                    currentSlide={currentSlide}
                    bulletsWrapperClassName={cx(
                        bulletsWrapperClassName,
                        'absolute transform -translate-x-1/2 left-1/2'
                    )}
                    bulletType={bulletType}
                    bulletsClassName={bulletsClassName}
                    bulletSize={bulletSize}
                />
            )}
        </div>
    );
};

Slider.defaultProps = {
    arrows: true,
    bullets: true,
    autoplay: false,
    bulletsWrapperClassName: 'bottom-2 md:bottom-6',
};

Slider.propTypes = {
    children: PropTypes.oneOfType([PropTypes.object, PropTypes.array, PropTypes.node]),
    sliderWrapperClassName: PropTypes.string,

    arrows: PropTypes.bool,
    arrowsWrapperClassName: PropTypes.string,
    arrowsClassName: PropTypes.string,
    arrowCircleSize: PropTypes.string,
    arrowCircleBg: PropTypes.string,
    arrowIconSize: PropTypes.string,

    bullets: PropTypes.bool,
    bulletsWrapperClassName: PropTypes.string,
    bulletType: PropTypes.string,
    bulletsClassName: PropTypes.string,
    bulletSize: PropTypes.string,

    id: PropTypes.string,
    options: PropTypes.object,
    autoplay: PropTypes.bool,
    autoplaySpeed: PropTypes.number,
    refresh: PropTypes.any,
};

export default Slider;
