import React from 'react';
import * as PropTypes from 'prop-types';
import { useRouter } from 'next/router';
import { get_, links } from 'sf-modules';
import BannerSlider from '@/yema-designkit/webkit/molecules/BannerSlider';

const CMS_Slider_Comp = ({ content }) => {
    const router = useRouter();
    const handleBtnClickhandleBtnClick = (e) => links.handleRedirect(e, router);

    return (
        <BannerSlider
            slides={get_(content, ['items'], [])}
            handleBtnClick={handleBtnClickhandleBtnClick}
        />
    );
};

CMS_Slider_Comp.defaultProps = {};

CMS_Slider_Comp.propTypes = {
    content: PropTypes.object.isRequired,
};

const CMS_Slider = React.memo(CMS_Slider_Comp);
export default CMS_Slider;
