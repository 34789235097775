import React from 'react';
import * as PropTypes from 'prop-types';
import cx from 'classnames';

const BulletIndicator = ({ className, bulletSize, type, onClick }) => {
    const bulletClassName = cx(className, 'transition duration-300 ease-out', {
        'rounded-full mx-1': type === 'dot',
        'w-3 h-3': type === 'dot' && !bulletSize,
        [bulletSize]: bulletSize,
        'w-full h-[5px]': type === 'rect',
    });

    return <button className={bulletClassName} onClick={onClick} />;
};

BulletIndicator.defaultProps = {
    type: 'dot',
};

BulletIndicator.propTypes = {
    className: PropTypes.string,
    bulletSize: PropTypes.string,
    type: PropTypes.oneOf(['dot', 'rect']),
    onClick: PropTypes.func,
};

export default BulletIndicator;
