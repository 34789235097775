import React from 'react';
import * as PropTypes from 'prop-types';
import cx from 'classnames';
import Button from '../../atoms/Button';

const Title = ({ children, isH1, className }) => {
    return isH1 ? (
        <h1 className={className}>{children}</h1>
    ) : (
        <h2 className={className}>{children}</h2>
    );
};

const BannerSliderItemCard = ({ info, isMobile, className, handleBtnClick }) => {
    const { button, text_color, title, is_h1, content, background_color, alignment } = info;
    const withButton = Boolean(button.link && button.text);
    const noBackground = background_color === 'no';
    const contentMarkup = { __html: content };

    return (
        <div
            className={cx(className, 'sm:min-h-0', {
                'min-h-48': !isMobile && alignment === 'center',
                'bg-ui-white': background_color === 'yes',
                'bg-transparent': background_color === 'no',
            })}
            style={
                background_color !== 'yes' && background_color !== 'no'
                    ? { backgroundColor: background_color }
                    : {}
            }
        >
            {title && (
                <Title
                    className='mb-4 font-heading text-h2 font-body-normal sm:mb-6 sm:text-sliderH1'
                    style={{ color: text_color ? text_color : '#000000' }}
                    isH1={is_h1}
                >
                    {title}
                </Title>
            )}

            {content && (
                <div
                    style={{ color: text_color || '#000000' }}
                    className='font-body font-body-regular'
                    dangerouslySetInnerHTML={contentMarkup}
                />
            )}

            {withButton && (
                <Button
                    size='md'
                    className={cx({
                        'mt-2': title || content,
                        'bg-opacity-90 sm:bg-opacity-100': !noBackground,
                    })}
                    onClick={handleBtnClick(button.link)}
                >
                    {button.text}
                </Button>
            )}
        </div>
    );
};

BannerSliderItemCard.defaultProps = {};

BannerSliderItemCard.propTypes = {
    info: PropTypes.object,
    className: PropTypes.string,
    handleBtnClick: PropTypes.func.isRequired,
};

export default BannerSliderItemCard;
